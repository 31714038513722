import { configureStore } from "@reduxjs/toolkit";
import GuideReducer from "../components/Guide/GuideSlice";
import NavBarRuducer from '../components/NavBar/NavBarSlice'
// import sharedLayoutReducer from "../pages/SharedLayout/SharedLayoutSlice";

const store = configureStore({
  reducer: {
    guide: GuideReducer,
    nav: NavBarRuducer,
    // language: LanguageReducer,
    // sharedLayout: sharedLayoutReducer,
  },
});

export default store;
