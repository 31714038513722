import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentscene: null,
    currentimage: null,
    activeMenu: 1,
    activeSubMenu: 1,
    activeChildMenu: 1,
  };

const navbarSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    updateScene: (state, action) => {
      state.currentscene = action.payload;
    },
    updateImage: (state, action) => {
        state.currentimage = action.payload;
    },
    setActiveMenu: (state, action) => {
        state.activeMenu = action.payload;
    },
    setActiveSubMenu: (state, action) => {
        state.activeSubMenu = action.payload;
    },
    setActiveChildMenu: (state, action) => {
        state.activeChildMenu = action.payload;
    },
  },
});

export default navbarSlice.reducer;
export const { updateScene, updateImage, setActiveMenu, setActiveSubMenu, setActiveChildMenu } = navbarSlice.actions;
