import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./FirstLoading.scss";

function Loading() {
  const navigate = useNavigate();
  useEffect(() => {
    const timerId = setTimeout(() => {
      navigate("/tour");
    }, 5000);
    return () => {
      clearTimeout(timerId);
    };
  }, [navigate]);
  return (
    <div className="FirstLoading_wrapper">

      <img src={"https://360.goku.agency/goku-data/charm-resort-long-hai/gallery/khac/bbq_activities.jpg"} alt="" />
      {/* Loader 1 */}
      {/* <span className="loader"></span> */}

      {/* Loader 2 */}
      <div className="dot-spinner">
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
        <div className="dot-spinner__dot"></div>
      </div>
    </div>
  );
}

export default Loading;
